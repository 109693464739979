<template>
  <div class="flex flex-col justify-center bg-gray-800 p-3 sm:p-5 rounded-2xl gap-3 sm:gap-5 max-w-3xl group w-full">
    <div class="relative">
      <div
        v-show="hasCameraPermissions == true && startWithCamera && cameraAllowed"
        class="flex justify-center relative bg-black rounded-3xl aspect-w-16 aspect-h-9 overflow-hidden"
      >
        <video id="video_camara" ref="video_camara" autoplay playsinline class="w-full object-cover"></video>
      </div>
      <div
        v-show="hasCameraPermissions == false || hasCameraPermissions == null || !startWithCamera || !cameraAllowed"
        class="bg-black relative rounded-3xl w-full max-w-5xl aspect-w-16 aspect-h-9 overflow-hidden"
      >
        <div
          v-if="!cameraAllowed"
          class="absolute top-1/2 h-56 -mt-24 left-0 right-0 gap-5 bottom-0 text-white text-lg font-semibold flex flex-col items-center"
        >
          <fw-icon-user class="h-24 w-24 text-gray-700 block" />
          O uso da câmara não está permitido nesta sala.
        </div>
        <div
          v-else-if="
            (hasCameraPermissions == false || hasCameraPermissions == null) &&
              (hasMicrophonePermissions == false || hasMicrophonePermissions == null) &&
              !startWithCamera
          "
          class="absolute top-1/2 h-56 -mt-24 left-0 right-0 gap-5 bottom-0 text-white text-lg font-semibold flex flex-col items-center"
        >
          <fw-icon-user class="h-24 w-24 text-gray-700 block" />
          Quer que as pessoas o ouçam e vejam a sua câmara?
          <fw-button type="primary" @click.native="showInstructions('both')">Ativar microfone e câmara</fw-button>
        </div>
        <div
          v-else-if="hasCameraPermissions == false || hasCameraPermissions == null || !startWithCamera"
          class="absolute top-1/2 h-56 -mt-24 left-0 right-0 gap-5 bottom-0 text-white text-lg font-semibold flex flex-col items-center"
        >
          <fw-icon-user class="h-24 w-24 text-gray-700 block" />
          Quer que as pessoas vejam a sua câmara?
          <fw-button
            v-if="hasCameraPermissions == true && !startWithCamera"
            type="primary"
            @click.native="$emit('toggle-camera-start-option')"
          >
            Ligar camara
          </fw-button>
          <fw-button v-else type="primary" @click.native="showInstructions('camera')">Ativar camara</fw-button>
        </div>
      </div>
      <div
        v-if="
          (hasMicrophonePermissions == false || hasMicrophonePermissions == null) &&
            startWithAudio &&
            hasCameraPermissions == true
        "
        class="flex bg-white absolute bottom-3 left-3 right-3 z-10 items-center gap-5 rounded-xl py-4 pl-5 pr-5"
      >
        <fw-icon-mic-off class="text-red-500" />
        <div class="flex-1">
          <div class="font-semibold">Quer que as pessoas o ouçam?</div>
          Dê permissões ao microfone para que os outros participantes o possam ouvir.
        </div>
        <fw-button type="primary" @click.native="showInstructions('microphone')">Saber como</fw-button>
      </div>
      <div
        v-if="(cameraQuality == 'HD' || cameraQuality == 'FHD') && hasCameraPermissions == true && startWithCamera"
        class="border-primary uppercase text-primary font-bold border-2 rounded-lg px-2 py-0.5 absolute top-4 right-4"
      >
        {{ cameraQuality }}
      </div>
    </div>
    <div class="grid grid-cols-3 gap-6 sm:gap-10">
      <div
        v-if="startWithAudio && audioAllowed"
        class="hidden sm:flex gap-1 rounded-full items-center justify-center bg-gray-900 px-5 py-1"
      >
        <div
          class="bg-primary/80 rounded w-1.5 min-h-1.5 max-h-8"
          :style="{ height: `${microphoneVolume / 1.6}%` }"
        ></div>
        <div
          class="bg-primary/80 rounded w-1.5 min-h-1.5 max-h-8"
          :style="{ height: `${microphoneVolume / 1.4}%` }"
        ></div>
        <div
          class="bg-primary/80 rounded w-1.5 min-h-1.5 max-h-8"
          :style="{ height: `${microphoneVolume / 1.2}%` }"
        ></div>
        <div
          class="bg-primary/80 rounded w-1.5 min-h-1.5 max-h-8"
          :style="{ height: `${microphoneVolume / 1}%` }"
        ></div>
        <div
          class="bg-primary/80 rounded w-1.5 min-h-1.5 max-h-8"
          :style="{ height: `${microphoneVolume / 1.2}%` }"
        ></div>
        <div
          class="bg-primary/80 rounded w-1.5 min-h-1.5 max-h-8"
          :style="{ height: `${microphoneVolume / 1.4}%` }"
        ></div>
        <div
          class="bg-primary/80 rounded w-1.5 min-h-1.5 max-h-8"
          :style="{ height: `${microphoneVolume / 1.6}%` }"
        ></div>
      </div>
      <div v-else class="hidden sm:flex gap-1 rounded-full items-center justify-center bg-gray-900 px-5 py-1">
        <div class="bg-gray-600 rounded w-1.5 min-h-1.5 max-h-8"></div>
        <div class="bg-gray-600 rounded w-1.5 min-h-1.5 max-h-8"></div>
        <div class="bg-gray-600 rounded w-1.5 min-h-1.5 max-h-8"></div>
        <div class="bg-gray-600 rounded w-1.5 min-h-1.5 max-h-8"></div>
        <div class="bg-gray-600 rounded w-1.5 min-h-1.5 max-h-8"></div>
        <div class="bg-gray-600 rounded w-1.5 min-h-1.5 max-h-8"></div>
        <div class="bg-gray-600 rounded w-1.5 min-h-1.5 max-h-8"></div>
      </div>

      <div class="sm:hidden flex items-center">
        <div
          v-if="hasMicrophonePermissions == true && startWithAudio && audioAllowed"
          class="w-full overflow-hidden rounded-lg"
        >
          <b-dropdown max-height="300" aria-role="list" position="is-bottom-right">
            <button
              slot="trigger"
              class="text-gray-200 bg-gray-700 p-2 text-sm font-medium rounded-lg bg-opacity-50 line-clamp-1 overflow-hidden overflow-ellipsis whitespace-nowrap w-full"
            >
              {{ audioInputDevice ? audioInputDevice.label : $t('selectaudiodevice') }}
            </button>

            <b-dropdown-item
              v-for="device in availableAudioInput"
              :key="device.deviceId"
              aria-role="menu-item"
              :focusable="false"
              class="font-semibold"
              paddingless
              custom
            >
              <fw-button type="transparent" size="sm" expanded @click.native="selectMicrophone(device)">
                <div class="inline-flex gap-2 items-center justify-start w-full whitespace-nowrap">
                  <div
                    class="h-2 w-2 rounded-full"
                    :class="{
                      'bg-gray-200': audioInputDevice && audioInputDevice.deviceId !== device.deviceId,
                      'bg-primary': audioInputDevice && audioInputDevice.deviceId === device.deviceId
                    }"
                  ></div>
                  <span class="line-clamp-1">{{ device.label }}</span>
                </div>
              </fw-button>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div
          v-else-if="!startWithAudio || !audioAllowed"
          class="flex cursor-pointer items-center gap-2 font-semibold text-red-500 text-sm py-1 pl-2"
          @click="$emit('toggle-audio-start-option')"
        >
          <fw-icon-mic-off class="h-5 w-5" />
          Desativado
        </div>
        <div
          v-else
          class="flex cursor-pointer items-center gap-2 font-semibold text-red-500 text-sm whitespace-nowrap"
          @click="askPermission('microphone')"
        >
          <fw-icon-mic-off class="h-5 w-5" />
          Sem permissões
        </div>
      </div>

      <div class="flex gap-3 sm:gap-5 items-center justify-center">
        <fw-button
          :type="startWithAudio && hasMicrophonePermissions === true && audioAllowed ? 'primary' : 'danger'"
          size="xl"
          custom-class="rounded-full w-[5rem] flex justify-center"
          @click.native="toogle('audio')"
        >
          <fw-icon-mic-line
            v-if="startWithAudio && hasMicrophonePermissions === true && audioAllowed"
            class="h-5 w-5"
          />
          <fw-icon-mic-off v-else class="h-5 w-5" />
        </fw-button>
        <fw-button
          :type="startWithCamera && hasCameraPermissions === true && cameraAllowed ? 'primary' : 'danger'"
          size="xl"
          custom-class="rounded-full w-[5rem] flex justify-center"
          @click.native="toogle('camera')"
        >
          <fw-icon-vidicon v-if="startWithCamera && hasCameraPermissions === true && cameraAllowed" class="h-5 w-5" />
          <fw-icon-camera-off v-else class="h-5 w-5" />
        </fw-button>
      </div>

      <div class="hidden sm:flex gap-1 rounded-full items-center bg-gray-900 justify-between px-5 py-1">
        <fw-button :type="cameraQuality == 'SD' ? 'link' : 'link-muted'" @click.native="changeVideoQuality('SD')"
          >SD</fw-button
        >
        <fw-button :type="cameraQuality == 'HD' ? 'link' : 'link-muted'" @click.native="changeVideoQuality('HD')"
          >HD</fw-button
        >
        <fw-button
          v-if="debugMode"
          :type="cameraQuality == 'FHD' ? 'link' : 'link-muted'"
          @click.native="changeVideoQuality('FHD')"
          >FHD</fw-button
        >
      </div>

      <div class="sm:hidden flex items-center">
        <div
          v-if="hasCameraPermissions == true && startWithCamera && cameraAllowed"
          class="w-full overflow-hidden rounded-lg"
        >
          <b-dropdown max-height="300" aria-role="list" position="is-bottom-right">
            <button
              slot="trigger"
              class="text-gray-200 bg-gray-700 p-2 text-sm font-medium rounded-lg bg-opacity-50 line-clamp-1 overflow-hidden overflow-ellipsis whitespace-nowrap w-full"
            >
              {{ videoInputDevice ? videoInputDevice.label : $t('selectvideodevice') }}
            </button>
            <b-dropdown-item
              v-for="device in availableVideoInput"
              :key="device.deviceId"
              aria-role="menu-item"
              :focusable="false"
              class="font-semibold"
              paddingless
              custom
            >
              <fw-button type="transparent" size="sm" expanded @click.native="selectVideo(device)">
                <div class="inline-flex gap-2 items-center justify-start w-full whitespace-nowrap">
                  <div
                    class="h-2 w-2 rounded-full"
                    :class="{
                      'bg-gray-200': videoInputDevice && videoInputDevice.deviceId !== device.deviceId,
                      'bg-primary': videoInputDevice && videoInputDevice.deviceId === device.deviceId
                    }"
                  ></div>
                  {{ device.label }}
                </div>
              </fw-button>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div
          v-else-if="!startWithCamera || !cameraAllowed"
          class="flex cursor-pointer items-center gap-2 font-semibold text-red-500 text-sm py-1 pl-2"
          @click="$emit('toggle-camera-start-option')"
        >
          <fw-icon-camera-off class="h-5 w-5" />
          Desativada
        </div>
        <div
          v-else
          class="flex cursor-pointer items-center gap-2 font-semibold text-red-500 text-sm whitespace-nowrap"
          @click="askPermission('camera')"
        >
          <fw-icon-camera-off class="h-5 w-5" />
          Sem permissões
        </div>
      </div>
    </div>
    <div class="hidden sm:grid grid-cols-3 gap-5 justify-start items-center opacity-95">
      <div>
        <div>
          <fw-label marginless size="xs">Microfone</fw-label>
        </div>
        <b-dropdown
          v-if="hasMicrophonePermissions == true && startWithAudio && audioAllowed"
          max-height="300"
          aria-role="list"
          position="is-bottom-right"
        >
          <fw-button
            slot="trigger"
            :aria-label="audioInputDevice ? audioInputDevice.label : $t('selectaudiodevice')"
            type="transparent"
            icon="mic-line"
            icon-right="chevron-down"
            size="xs"
            class="text-gray-200 bg-gray-700 bg-opacity-50"
          >
            <span class="line-clamp-1">{{ audioInputDevice ? audioInputDevice.label : $t('selectaudiodevice') }}</span>
          </fw-button>

          <b-dropdown-item
            v-for="device in availableAudioInput"
            :key="device.deviceId"
            aria-role="menu-item"
            :focusable="false"
            class="font-semibold"
            paddingless
            custom
          >
            <fw-button type="transparent" size="sm" expanded @click.native="selectMicrophone(device)">
              <div class="inline-flex gap-2 items-center justify-start w-full whitespace-nowrap">
                <div
                  class="h-2 w-2 rounded-full"
                  :class="{
                    'bg-gray-200': audioInputDevice && audioInputDevice.deviceId !== device.deviceId,
                    'bg-primary': audioInputDevice && audioInputDevice.deviceId === device.deviceId
                  }"
                ></div>
                <span class="line-clamp-1">{{ device.label }}</span>
              </div>
            </fw-button>
          </b-dropdown-item>
        </b-dropdown>
        <div
          v-else-if="!startWithAudio || !audioAllowed"
          class="flex cursor-pointer items-center gap-2 font-semibold text-red-500 text-sm py-1 pl-2"
          @click="$emit('toggle-audio-start-option')"
        >
          <fw-icon-mic-off class="h-5 w-5" />
          Microfone desativado
        </div>
        <div
          v-else
          class="flex cursor-pointer items-center gap-2 font-semibold text-red-500 text-sm"
          @click="askPermission('microphone')"
        >
          <fw-icon-mic-off class="h-5 w-5" />
          Sem permissões
        </div>
      </div>
      <div>
        <div>
          <fw-label marginless size="xs">Video</fw-label>
        </div>
        <b-dropdown
          v-if="hasCameraPermissions == true && startWithCamera && cameraAllowed"
          max-height="300"
          aria-role="list"
          position="is-bottom-right"
        >
          <fw-button
            slot="trigger"
            :aria-label="videoInputDevice ? videoInputDevice.label : $t('selectvideodevice')"
            type="transparent"
            icon="vidicon"
            icon-right="chevron-down"
            size="xs"
            class="text-gray-200 bg-gray-700 bg-opacity-50"
          >
            <span class="line-clamp-1">{{ videoInputDevice ? videoInputDevice.label : $t('selectvideodevice') }}</span>
          </fw-button>

          <b-dropdown-item
            v-for="device in availableVideoInput"
            :key="device.deviceId"
            aria-role="menu-item"
            :focusable="false"
            class="font-semibold"
            paddingless
            custom
          >
            <fw-button type="transparent" size="sm" expanded @click.native="selectVideo(device)">
              <div class="inline-flex gap-2 items-center justify-start w-full whitespace-nowrap">
                <div
                  class="h-2 w-2 rounded-full"
                  :class="{
                    'bg-gray-200': videoInputDevice && videoInputDevice.deviceId !== device.deviceId,
                    'bg-primary': videoInputDevice && videoInputDevice.deviceId === device.deviceId
                  }"
                ></div>
                {{ device.label }}
              </div>
            </fw-button>
          </b-dropdown-item>
        </b-dropdown>
        <div
          v-else-if="!startWithCamera || !cameraAllowed"
          class="flex cursor-pointer items-center gap-2 font-semibold text-red-500 text-sm py-1 pl-2"
          @click="$emit('toggle-camera-start-option')"
        >
          <fw-icon-camera-off class="h-5 w-5" />
          Câmara desativada
        </div>
        <div
          v-else
          class="flex cursor-pointer items-center gap-2 font-semibold text-red-500 text-sm"
          @click="askPermission('camera')"
        >
          <fw-icon-camera-off class="h-5 w-5" />
          Sem permissões
        </div>
      </div>
      <div v-if="hasMicrophonePermissions == true">
        <div>
          <fw-label marginless size="xs">Saída de som</fw-label>
        </div>
        <b-dropdown max-height="300" aria-role="list" position="is-bottom-right">
          <fw-button
            slot="trigger"
            :aria-label="audioOutputDevice ? audioOutputDevice.label : $t('selectoutputdevice')"
            type="transparent"
            icon="volume-lines"
            icon-right="chevron-down"
            size="xs"
            class="text-gray-200 bg-gray-700 bg-opacity-50"
          >
            <span class="line-clamp-1">{{
              audioOutputDevice ? audioOutputDevice.label : $t('selectoutputdevice')
            }}</span>
          </fw-button>

          <b-dropdown-item
            v-for="device in availableAudioOutput"
            :key="device.deviceId"
            aria-role="menu-item"
            :focusable="false"
            class="font-semibold"
            paddingless
            custom
          >
            <fw-button type="transparent" expanded size="sm" @click.native="selectOutput(device)">
              <div class="inline-flex gap-2 items-center justify-start w-full whitespace-nowrap">
                <div
                  class="h-2 w-2 rounded-full"
                  :class="{
                    'bg-gray-200': audioOutputDevice && audioOutputDevice.deviceId !== device.deviceId,
                    'bg-primary': audioOutputDevice && audioOutputDevice.deviceId === device.deviceId
                  }"
                ></div>
                {{ device.label }}
              </div>
            </fw-button>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
export default {
  name: 'BlockVideoAudioTest',
  props: {
    meeting: {
      type: Object,
      default: null
    },
    startWithAudio: {
      type: Boolean,
      default: true
    },
    startWithCamera: {
      type: Boolean,
      default: true
    },
    audio: {
      type: Object,
      default: null
    },
    camera: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      hasCameraPermissions: null,
      hasMicrophonePermissions: null,
      availableVideoInput: [],
      availableAudioInput: [],
      availableAudioOutput: [],
      audioInputDevice: null,
      audioOutputDevice: null,
      videoInputDevice: null,
      microphoneVolume: 0,
      speaking: false,
      analyser: null,
      audioStream: null,
      videoStream: null,
      audioAllowed: false,
      cameraAllowed: false,
      cameraQuality: 'HD',
      debugMode: localStorage.getItem('fw-debug') === 'true'
    }
  },
  async mounted() {
    if (localStorage.getItem('video_quality')) {
      this.cameraQuality = localStorage.getItem('video_quality')
    } else {
      localStorage.setItem('video_quality', 'HD')
      localStorage.setItem('device.video.hd', true)
    }
    //await this.checkPermissions()
    this.listDevices()
    this.audioAllowed = this.meeting && ServiceMeetings.withRole(this.meeting.roles, 'audio_allowed')
    this.cameraAllowed = this.meeting && ServiceMeetings.withRole(this.meeting.roles, 'camera_allowed')
  },
  methods: {
    changeVideoQuality(quality) {
      this.cameraQuality = quality
      localStorage.setItem('video_quality', quality)
      if (quality === 'SD') {
        localStorage.setItem('device.video.hd', false)
      } else if (quality === 'FHD') {
        localStorage.setItem('device.video.hd', true)
        localStorage.setItem('device.video.fhd', true)
      } else {
        localStorage.setItem('device.video.hd', true)
      }
    },
    toogle(type) {
      if (type === 'audio' && this.audioAllowed) {
        this.$emit('toggle-audio-start-option')
      } else if (type === 'camera' && this.cameraAllowed) {
        this.$emit('toggle-camera-start-option')
      }
    },
    showInstructions(type = 'microphone') {
      let intructions = {
        microphone:
          'Necessita de permitir o acesso ao microfone para que os outros participantes o possam ouvir. Vá às definições do seu navegador e permita o acesso ao microfone.',
        camera:
          'Necessita de permitir o acesso à câmara para que os outros participantes o possam ver. Vá às definições do seu navegador e permita o acesso à câmara.',
        both:
          'Para que os outros o possam ver e ouvir, necessita de permitir o acesso à câmara e ao microfone. Vá às definições do seu navegador e permita o acesso à câmara e ao microfone.'
      }
      //type can be 'microphone' or 'camera', or 'both'
      console.log('Show permissions instructions')
      this.$buefy.dialog.alert({
        title: 'Permissões necessárias',
        message: intructions[type]
      })
    },
    askPermission(type) {
      if (type === 'camera') {
        this.createCameraStream()
      } else if (type === 'microphone') {
        this.createAudioStream()
      }
    },
    async checkPermissions(microfone = true, camera = true) {
      try {
        if (microfone) {
          // Check camera permission
          const cameraPermission = await navigator.permissions.query({ name: 'camera' })
          console.log('Camera Permission:', cameraPermission.state) // "granted", "denied", or "prompt"
          if (cameraPermission.state === 'granted') {
            this.hasCameraPermissions = true
          } else if (cameraPermission.state === 'prompt') {
            this.hasCameraPermissions = null
          } else {
            //it was denied
            this.hasCameraPermissions = false
          }
        }
        if (camera) {
          // Check microphone permission
          const microphonePermission = await navigator.permissions.query({ name: 'microphone' })
          console.log('Microphone Permission:', microphonePermission.state) // "granted", "denied", or "prompt"
          if (microphonePermission.state === 'granted') {
            this.hasMicrophonePermissions = true
          } else if (microphonePermission.state === 'prompt') {
            this.hasMicrophonePermissions = null
          } else {
            //it was denied
            this.hasMicrophonePermissions = false
          }
        }
      } catch (error) {
        console.error('Error checking permissions:', error)
      }
    },
    async createCameraStream() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach(track => track.stop())
      }
      const video = this.$refs.video_camara
      const constraints = {
        video: true
      }
      if (this.videoInputDevice !== null) {
        constraints.video = {
          deviceId: this.videoInputDevice.deviceId
        }
      }
      try {
        this.videoStream = await navigator.mediaDevices.getUserMedia(constraints)
        //this.hasCameraPermissions = true
        video.srcObject = this.videoStream
      } catch (error) {
        console.error('Error accessing the camera: ', error)
      }
    },
    async createAudioStream() {
      if (this.audioStream) {
        this.audioStream.getTracks().forEach(track => track.stop())
      }
      try {
        // Get the audio stream from the user's microphone, use the selected device
        const constraints = {
          audio: true
        }
        if (this.audioInputDevice !== null) {
          constraints.audio = {
            deviceId: this.audioInputDevice.deviceId
          }
        }
        this.audioStream = await navigator.mediaDevices.getUserMedia(constraints)
        const audioContext = new (window.AudioContext || window.webkitAudioContext)()
        const source = audioContext.createMediaStreamSource(this.audioStream)

        // Create an AnalyserNode to analyze the audio data
        this.analyser = audioContext.createAnalyser()
        this.analyser.fftSize = 256 // Smaller FFT size for quicker detection
        source.connect(this.analyser)

        // Start detecting speech
        this.detectSpeech()
      } catch (error) {
        console.error('Error accessing audio devices.', error)
      }
    },
    detectSpeech() {
      // Data array to hold audio analysis data
      const dataArray = new Uint8Array(this.analyser.frequencyBinCount)

      // Get the audio data
      this.analyser.getByteFrequencyData(dataArray)

      // Calculate the average volume
      const averageVolume = dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length

      // Set a threshold for detecting speech
      const threshold = 40 // Adjust this value based on your environment

      this.microphoneVolume = averageVolume * 1.25

      // Change the indicator color based on speech detection
      if (averageVolume > threshold) {
        this.speaking = true
      } else {
        this.speaking = false
      }
      // Continue analyzing in the next animation frame
      requestAnimationFrame(this.detectSpeech)
    },
    selectVideo(device) {
      this.videoInputDevice = device
      localStorage.setItem('device.video.input', device.deviceId)
      if (this.camera) {
        this.camera.setDevice(device.deviceId)
      }
      //create stream with selected device to display animation
      this.createCameraStream()
    },
    selectMicrophone(device) {
      this.audioInputDevice = device
      localStorage.setItem('device.audio.input', device.deviceId)
      if (this.audio) {
        this.audio.setDevice(device.deviceId)
      }
      //create stream with selected device to display animation
      this.createAudioStream()
    },
    selectOutput(device) {
      this.audioOutputDevice = device
      localStorage.setItem('device.audio.output', device.deviceId)
    },
    isGetUserMediaAvailable() {
      return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
    },
    listDevices() {
      let self = this
      console.log('=== List devices')
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(respo => {
          console.log('=== getUserMedia success', respo)
          navigator.mediaDevices.enumerateDevices().then(devices => {
            //self.availableDevices = devices
            console.log('Available devices: ', devices)
            //separate devices by kind
            devices.forEach(device => {
              if (device.kind === 'videoinput') {
                self.availableVideoInput.push(device)
              } else if (device.kind === 'audioinput') {
                self.availableAudioInput.push(device)
              } else if (device.kind === 'audiooutput') {
                self.availableAudioOutput.push(device)
              }
            })

            //set default devices
            let found = null
            if (self.availableVideoInput.length > 0) {
              this.hasCameraPermissions = true
              if (localStorage.getItem('device.video.input')) {
                found = self.availableVideoInput.find(
                  device => device.deviceId === localStorage.getItem('device.video.input')
                )
                if (found) {
                  self.videoInputDevice = found
                } else {
                  self.videoInputDevice = self.availableVideoInput[0]
                }
              } else {
                self.videoInputDevice = self.availableVideoInput[0]
              }
            }

            if (self.availableAudioInput.length > 0) {
              this.hasMicrophonePermissions = true
              if (localStorage.getItem('device.audio.input')) {
                found = self.availableAudioInput.find(
                  device => device.deviceId === localStorage.getItem('device.audio.input')
                )
                if (found) {
                  self.audioInputDevice = found
                } else {
                  self.audioInputDevice = self.availableAudioInput[0]
                }
              } else {
                self.audioInputDevice = self.availableAudioInput[0]
              }
            }
            if (self.availableAudioOutput.length > 0) {
              if (localStorage.getItem('device.audio.output')) {
                found = self.availableAudioOutput.find(
                  device => device.deviceId === localStorage.getItem('device.audio.output')
                )
                if (found) {
                  self.audioOutputDevice = found
                } else {
                  self.audioOutputDevice = self.availableAudioOutput[0]
                }
              } else {
                self.audioOutputDevice = self.availableAudioOutput[0]
              }
            }
            try {
              //start audio stream
              self.createAudioStream()
              //start video stream
              self.createCameraStream()
            } catch (error) {
              console.error('Error starting streams: ', error)
            }
          })
        })
        .catch(err => {
          console.error('Error listing devices: ', err)
        })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "allowcameramessage": "You need to allow the camera to see the video",
    "activatecamerabutton": "Activate camera",
    "selectaudiodevice": "Select audio device",
    "selectoutputdevice": "Select output device",
    "selectvideodevice": "Select video device"
  },
  "es": {
    "allowcameramessage": "Necesitas permitir la cámara para ver el video",
    "activatecamerabutton": "Activar cámara",
    "selectaudiodevice": "Seleccionar dispositivo de audio",
    "selectoutputdevice": "Seleccionar dispositivo de saída",
    "selectvideodevice": "Seleccionar dispositivo de vídeo"
  }
}
</i18n>
