import CoreMutations from '@/fw-modules/fw-core-vue/store/mutations'
import Vue from 'vue'

export default Object.assign(CoreMutations, {
  setSavingData(state, data) {
    state.isSaving = data
  },
  setUserTelework(state, data) {
    Vue.set(state.session, 'isTeleworker', data)
  },
  setUserPermissions(state, data) {
    state.session.permissions = data
  },
  setUserPermissionsLoaded(state, loaded = false) {
    state.session.permissionsLoaded = loaded
  },
  setLastPaySlip(state, data) {
    localStorage.setItem('session.receipt', JSON.stringify(data))

    state.lastPaySlip = data
  },
  setLastIrs(state, data) {
    localStorage.setItem('session.irs', JSON.stringify(data))

    state.lastIrs = data
  },
  saveCurrentScrollPos(state, data) {
    state.tmbScrollPos[data.key] = data.pos
  },
  deleteCurrentScrollPos(state, data) {
    delete state.tmbScrollPos[data]
  }
})
