var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listLoader"},[(_vm.list)?_c('div',_vm._l((_vm.items),function(n){return _c('div',{key:'loading_row_' + n,staticClass:"my-2 rounded-lg flex"},[_c('div',{staticClass:"animate-pulse h-6 rounded",class:{
          'w-4/6': n % 3 === 2,
          'w-1/2': n % 3 === 1,
          'w-2/6': n % 3 === 0,
          'bg-gray-200': !_vm.dark,
          'bg-444': _vm.dark
        }})])}),0):_c('div',{staticClass:"grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-10 py-2"},_vm._l((_vm.items),function(n){return _c('div',{key:'loading_card_' + n,staticClass:"animate-pulse w-full shadow group h-full flex items-center justify-center flex-col p-4 rounded-xl",class:{
        'bg-gray-200': !_vm.dark,
        'bg-444': _vm.dark
      }},[_c('div',{staticStyle:{"height":"130px"}})])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }