<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.removeSW()
  },
  methods: {
    removeSW() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          for (const registration of registrations) {
            registration.unregister()
            //ignore no-console
            // eslint-disable-next-line no-console
            console.log('ServiceWorker removed!')
          }
        })
      }
    }
  }
}
</script>
