export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home'),
    meta: { requiresAuth: true }
  },
  {
    path: '/absences',
    name: 'absences',
    component: () => import('@/views/Absences'),
    meta: { requiresAuth: true }
  },
  {
    path: '/receipts',
    name: 'receipts',
    component: () => import('@/views/Receipts'),
    meta: { requiresAuth: true }
  },
  {
    path: '/irs',
    name: 'irs',
    component: () => import('@/views/Irs'),
    meta: { requiresAuth: true }
  },
  {
    path: '/personal-details',
    name: 'personal-details',
    component: () => import('@/views/PersonalDetails'),
    meta: { requiresAuth: true }
  },
  {
    path: '/attendances',
    name: 'attendances',
    component: () => import('@/views/Attendances'),
    meta: { requiresAuth: true }
  },
  {
    path: '/justifications',
    name: 'justifications',
    component: () => import('@/views/Justifications'),
    meta: { requiresAuth: true }
  },
  {
    path: '/justification/:key',
    name: 'absence-justification',
    component: () => import('@/views/Justification'),
    meta: { requiresAuth: true }
  },
  {
    path: '/justification/:key/:section',
    name: 'absence-justification-sections',
    component: () => import('@/views/Justification'),
    meta: { requiresAuth: true }
  },
  {
    path: '/maps',
    name: 'maps',
    component: () => import('@/views/Maps'),
    meta: { requiresAuth: true }
  },
  {
    path: '/certificates',
    name: 'certificates',
    component: () => import('@/views/Certificates'),
    meta: { requiresAuth: true }
  },
  {
    path: '/maps/:key',
    name: 'map',
    component: () => import('@/views/Map'),
    meta: { requiresAuth: true }
  }
]
