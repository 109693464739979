import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

const CPU = navigator && navigator.hardwareConcurrency ? navigator.hardwareConcurrency : 0

export default {
  data() {
    return this.getPodsDefaultData()
  },

  watch: {
    waitingAttendees(waitingList) {
      if (!waitingList.length) {
        this.ModalRequestToEnterIsActive = false
      } else if (!this.previousWaitingAttendees) {
        this.ModalRequestToEnterIsActive = true
        this.$notification.show(
          'Novos participantes aguardam para entrar',
          { body: 'Existem novos participantes a solicitar autorização para entrar na sessão.' },
          {}
        )
      }

      this.previousWaitingAttendees = waitingList.length
    }
  },

  methods: {
    getPodsDefaultData() {
      const self = this
      const data = {
        selfPodSize: 'normal',
        podsQueue: {
          // Run with order
          addAttendees: [],
          refreshAttendeeVideos: [],
          addStreams: [],
          removeStreams: [],
          remove: []
        },
        podsLeftQueue: {
          names: [],
          nextRunMs: null,
          onHold: false
        },
        podsQueueVideoStream: new Set(),
        podsQueuePositions: new Set(),
        podsQueueChangeToPage: undefined,
        podsQueueRunning: false,
        podsFindAndFocusId: null,

        attendees: {},
        roomAttendees: [],
        pods: [],
        activePods: [],
        podsLength: 0,
        podsById: {},
        removingPod: new Set(),
        podsCounter: 0,
        podsPerPage: 12,
        podWidth: null,
        podHeight: null,
        pageSettings: {
          minPodWidth: 140,
          minPodHeight: 140 / (16 / 9), // keep exact value to calc ratio
          default: !CPU || CPU > 4 ? 12 : CPU > 2 ? 8 : 6,
          current: 12,
          min: 2,
          max: this.$device.isMobile() ? 16 : 50,
          warnAfter: this.$device.isMobile() ? 10 : 16,
          setPods: function(value, ignoreLocalStorage = false) {
            let oldCurrent = this.current
            this.current = Math.max(this.min, Math.min(this.max, parseInt(value) || this.default))
            if (oldCurrent !== this.current) {
              if (oldCurrent > this.current) {
                for (let idx = this.current; idx < oldCurrent; idx++) {
                  let pod = data.pods[idx]
                  if (pod) {
                    pod.checkVisibility(true)
                  } else {
                    break
                  }
                }
              }

              self.reloadPage()
            }

            if (!ignoreLocalStorage) {
              localStorage.setItem('pods.max', this.current)
              console.info(`Max pods updated to ${this.current}`)
            }
          },
          activeSpeaker: true,
          setActiveSpeaker: function(value, ignoreLocalStorage = false) {
            value = !!value
            if (value !== this.activeSpeaker) {
              this.activeSpeaker = value
              if (!ignoreLocalStorage) {
                localStorage.setItem('pods.active.speaker', this.activeSpeaker)
                console.info(`Active speaker updated to ${this.activeSpeaker}`)
              }
            }
          },
          activeVideo: true,
          setActiveVideo: function(value, ignoreLocalStorage = false) {
            value = !!value
            if (value !== this.activeVideo) {
              this.activeVideo = value
              if (!ignoreLocalStorage) {
                localStorage.setItem('pods.active.video', this.activeVideo)
                console.info(`Active video updated to ${this.activeVideo}`)
              }
            }
          },
          fillPod: false,
          setFillPod: function(value, ignoreLocalStorage = false) {
            value = !!value
            if (value !== this.fillPod) {
              this.fillPod = value
              if (!ignoreLocalStorage) {
                localStorage.setItem('pods.fill.pod', this.fillPod)
                console.info(`Fill pod updated to ${this.fillPod}`)
              }
            }
          }
        },
        numberOfPages: 0,
        activePage: 1,
        currentPagePods: 0,
        attendeesHandsUp: [],
        withHandsUp: false,
        waitingAttendees: [],
        previousWaitingAttendees: 0,
        ModalRequestToEnterIsActive: false,
        ModalRemoveAttendee: null,

        permissionsAttendee: null,
        permissionsAttendeeModalActive: false,
        bulkPermissionsModalActive: false,

        privateToggleFullscreenPod: null,
        activeFullscreenPod: null,
        withFullscreen: false,
        floatingStreamsVisible: !localStorage.getItem('pods.visible')
          ? true
          : localStorage.getItem('pods.visible') === 'true',
        podsSubmenu: false,
        chatSubmenu: false
      }
      //restore settings from localstorage
      data.pageSettings.setPods(localStorage.getItem('pods.max'), true)
      data.pageSettings.setActiveSpeaker(localStorage.getItem('pods.active.speaker') !== 'false', true)
      data.pageSettings.setFillPod(localStorage.getItem('pods.fill.pod') === 'true', false)
      data.pageSettings.setActiveVideo(localStorage.getItem('pods.active.video') !== 'false', true)
      return data
    },

    async runPodsQueue() {
      if (this.podsQueueRunning) return
      this.podsQueueRunning = true

      try {
        while (this.podsQueueRunning) {
          try {
            if (this.podsQueue.addAttendees.length) {
              await this.privateAddAttendeePod(this.podsQueue.addAttendees.shift())
              continue
            } else if (this.podsQueue.refreshAttendeeVideos.length) {
              await this.privateRefreshAttendeeVideo(this.podsQueue.refreshAttendeeVideos.shift())
              continue
            } else if (this.podsQueue.addStreams.length) {
              await this.privateAddStreamPod(this.podsQueue.addStreams.shift())
              continue
            } else if (this.podsQueue.removeStreams.length) {
              await this.privateRemoveStreamPod(this.podsQueue.removeStreams.shift())
              continue
            } else if (this.privateToggleFullscreenPod) {
              await this.privateBuildToggleFullscreenPod()
            } else if (this.podsQueue.remove.length) {
              await this.privateRemovePod(this.podsQueue.remove.shift())
              continue
            } else if (this.podsQueueVideoStream.size) {
              let podId = null
              for (podId of this.podsQueueVideoStream) break
              this.podsQueueVideoStream.delete(podId)
              await this.privateSetPodVideoStream(podId)
              continue
            } else if (this.podsQueuePositions.size) {
              let podId = null
              for (podId of this.podsQueuePositions) break
              this.podsQueuePositions.delete(podId)
              await this.privateAdjustPodPosition(podId)
              continue
            }

            // Not blockers
            if (!this.podsLeftQueue.onHold && this.podsLeftQueue.names.length) {
              let waitingMs = this.podsLeftQueue.nextRunMs - new Date().getTime()
              if (waitingMs <= 0) {
                let names = this.podsLeftQueue.names
                this.podsLeftQueue.names = []
                await this.privatePodsleftMessage(names)
              } else {
                this.podsLeftQueue.onHold = true
                setTimeout(() => {
                  this.podsLeftQueue.onHold = false
                  this.runPodsQueue()
                }, waitingMs + 1)
              }
            }
          } catch (error) {
            console.error('Failed to run', error)
          }

          // After pods add/remove build page
          let newPage = this.podsQueueChangeToPage === undefined ? null : this.podsQueueChangeToPage
          this.podsQueueChangeToPage = undefined
          await this.privateSetPageData(newPage)
          await this.privateBuildActivePagePods()

          if (this.podsFindAndFocusId) {
            try {
              await this.privateFindAndFocus(this.podsFindAndFocusId)
            } finally {
              this.podsFindAndFocusId = null
            }
          }

          // No more items in queue? Stop the loop
          if (
            this.podsQueueVideoStream.size ||
            this.podsQueuePositions.size ||
            this.podsQueueChangeToPage !== undefined
          ) {
            continue
          }

          let queueWithItems = false
          for (let queueItems of Object.values(this.podsQueue)) {
            if (queueItems.length) {
              queueWithItems = true
              break
            }
          }
          if (!queueWithItems) break
        }
      } finally {
        this.podsQueueRunning = false
      }
    },

    async privateAdjustPodPosition(podId) {
      let pod = this.podsById[podId]
      if (!pod) return

      if (process.env.VUE_APP_KEY === 'ucmeetingscreen') {
        this.reloadPage()
        return
      }

      let startIdx = this.pods.indexOf(pod)
      if (startIdx + pod.children.length < this.podsPerPage) {
        // Already in first page
        return
      }

      let listPod
      let newIdx = null
      for (let idx = 0; idx < this.pods.length; idx++) {
        if (idx >= this.podsPerPage) break
        else {
          listPod = this.pods[idx]
          if (listPod.nextToPod || listPod.rating > pod.rating) {
            continue
          } else {
            newIdx = idx
            break
          }
        }
      }

      if (newIdx !== null && newIdx < startIdx) {
        this.privateMovePod(pod, newIdx)

        // Now we need to check if we moved some pods with better rating to second page
        let nextIdx = newIdx + pod.children.length + 1
        const podsMaxCheck = Math.min(this.podsPerPage + 1 + pod.children.length, this.pods.length)
        while (nextIdx < this.podsPerPage - 1) {
          let biggestPod = null
          for (let idx = nextIdx; idx < podsMaxCheck; idx++) {
            listPod = this.pods[idx]
            if (!listPod.nextToPod && (!biggestPod || biggestPod.rating < listPod.rating)) {
              biggestPod = listPod
            }
          }

          if (!biggestPod) {
            break
          } else {
            this.privateMovePod(biggestPod, nextIdx)
            nextIdx += 1 + biggestPod.children.length
          }
        }

        this.reloadPage()
      }
    },
    privateMovePod(pod, newIdx) {
      let startIdx = this.pods.indexOf(pod)
      console.info(
        `Pod ${pod.id} moved ${startIdx}=>${newIdx} talking:${pod.talking} rating:${pod.rating} name:${pod.name}`
      )

      this.pods.splice(startIdx, 1 + pod.children.length)
      this.pods.splice(newIdx, 0, pod)
      if (pod.children.length) this.pods.splice(newIdx + 1, 0, ...pod.children)
    },
    async privateFindAndFocus(podId) {
      let pod = this.podsById[podId]
      if (!pod) return

      const podPage = this.findPodPage(pod)
      if (podPage && this.activePage && podPage !== this.activePage) {
        this.changePage(podPage)
      }

      console.debug(`Set full screen for ${pod.name} podPage:${podPage} active:${this.activePage}`)
      this.privatePodToFullscreen(pod)
    },
    privatePodToFullscreen(pod, retry = 0) {
      retry = retry || 0
      if (!pod.elementCreated && retry < 3) {
        retry += 1
        this.$nextTick(() => {
          this.privatePodToFullscreen(pod, retry)
        })
      } else {
        this.setPodToFullscreen(pod)
      }
    },

    privateAddPod(pod, nextToPod) {
      pod.elementCreated = false
      pod.fullscreen = false
      pod.id = 'pod-' + this.podsCounter
      this.podsCounter += 1

      pod.children = []
      pod.nextToPod = null
      if (nextToPod && !nextToPod.nextToPod) {
        pod.nextToPod = nextToPod
        nextToPod.children.push(pod)
        nextToPod.checkVisibility()
      }

      const self = this
      pod.mounted = function() {
        pod.elementCreated = true
        self.syncPodVideoStream(pod)
      }
      pod.beforeDestroy = function() {
        pod.elementCreated = false
      }

      pod.rating = 0
      pod.checkVisibility = function(force = false) {
        if (pod.nextToPod) {
          // We dont care about child (screen share) ratings
          pod.nextToPod.checkVisibility()
        } else {
          const oldRating = pod.rating

          pod.rating = 0
          for (const child of pod.children) {
            if (child.type === 'screen_share') {
              child.rating += 2
            } else {
              child.rating += 1
            }
          }

          if (pod.isUcMiddleware) {
            pod.rating += 4
          }

          if (pod.attendee) {
            if (pod.attendee.withRole('is_host')) pod.rating += 1
            if (self.pageSettings.activeVideo && pod.video) pod.rating += 1
            if (self.pageSettings.activeSpeaker && pod.talking) pod.rating += 2
          }

          if (force || oldRating < pod.rating) {
            self.podsQueuePositions.add(pod.id)
            self.runPodsQueue()
          }
        }
      }

      if (!pod.nextToPod) {
        this.pods.push(pod)
      } else {
        let podIdx = this.pods.indexOf(pod.nextToPod)
        if (pod.type === 'screen_share') podIdx += 1
        else podIdx += pod.nextToPod.children.length
        this.pods.splice(podIdx, 0, pod)
      }

      this.podsById[pod.id] = pod
      this.podsLength += 1

      if (pod.nextToPod) {
        console.debug(`Pod ${pod.id} added next to ${pod.nextToPod.id}`, pod)
      } else {
        console.debug(`Pod ${pod.id} added`, pod)
      }

      pod.checkVisibility()
    },
    async privateAddAttendeePod(key) {
      const attendee = this.attendees[key]
      if (!attendee || attendee.pod) return

      if (attendee.camera_id) this.addStreamSubscriber('camera', attendee.camera_id)
      if (attendee.screen_share_id) this.addStreamSubscriber('screen_share', attendee.screen_share_id, attendee)

      // Ignore ucmiddleware main pod
      // privateAddStreamPod is going to add the screen
      if (attendee.withRole('is_room_plugin')) return

      const pod = {
        type: 'attendee',
        name: this.buildAttendeeName(attendee),
        attendee: attendee,
        isUcMiddleware: false,
        withAudio: !!this.audios[key],
        talking: false,
        video: null,
        filters: {
          grayscale: false
        }
      }
      attendee.pod = pod

      let nextToPod = null
      if (attendee.plugin_for_attendee_key) {
        pod.name += ' - Plugin'
        const mainAttendee = this.attendees[attendee.plugin_for_attendee_key]
        if (mainAttendee) nextToPod = mainAttendee.pod
      }

      this.privateAddPod(attendee.pod, nextToPod)
    },
    async privateRefreshAttendeeVideo(key) {
      let attendee = this.attendees[key]
      if (!attendee || !attendee.pod) return

      let pod = attendee.pod
      let syncPod = false
      if (pod.video && (!attendee.camera_id || pod.video.id !== attendee.camera_id)) {
        this.removeStreamSubscriber('camera', pod.video.id)
        pod.video.pod = null
        pod.video = null
        syncPod = true
      }
      if (attendee.camera_id && (!pod.video || pod.video.id !== attendee.camera_id)) {
        let camera = this.streamsById[attendee.camera_id]
        if (camera) {
          if (camera.pod) {
            this.removePod(camera.pod)
            this.syncPodVideoStream(camera.pod)
          }

          pod.video = camera
          pod.video.pod = pod
          syncPod = true
          console.debug('Camera added to attendee', attendee)
        } else {
          this.addStreamSubscriber('camera', attendee.camera_id)
        }
      }

      if (syncPod) this.syncPodVideoStream(pod)
    },
    async privateAddStreamPod(streamId) {
      let stream = this.streamsById[streamId]
      if (!stream || stream.type === 'audio') return

      // Try to find an attendee with this stream defined
      let pod = null
      let isUcMiddleware = false
      for (let attendee of Object.values(this.attendees)) {
        if (attendee.camera_id === streamId) {
          if (attendee.pod) {
            pod = attendee.pod
            pod.video = stream
            pod.video.pod = pod
            this.syncPodVideoStream(pod)
            break
          } else if (attendee.withRole('is_room_plugin')) {
            isUcMiddleware = true
            break
          }
        } else if (attendee.screen_share_id === streamId && attendee.withRole('is_room_plugin') && !attendee.pod) {
          isUcMiddleware = true
          break
        }
      }

      if (!pod) {
        let nextToPod = null
        let name = stream.id
        const subscriberAttendee = stream.subscriber.attendee
        if (subscriberAttendee) {
          name = this.buildAttendeeName(subscriberAttendee) + ' - Partilha de ecrã'
          nextToPod = subscriberAttendee.pod
        }

        stream.pod = pod = {
          type: stream.type,
          name: name,
          attendee: null,
          isUcMiddleware,
          withAudio: false,
          talking: false,
          video: stream
        }

        this.syncPodVideoStream(pod)
        this.privateAddPod(pod, nextToPod)
      } else {
        pod.checkVisibility()
      }

      // Show alert if this is a screen share
      if (this.view !== 'participants' && stream.type === 'screen_share' && !pod.isUcMiddleware) {
        this.$buefy.notification.open({
          message: 'Foi iniciada uma nova partilha de ecrã',
          type: 'is-primary',
          duration: 5000,
          queue: false
        })
      }
      if (stream.type === 'screen_share') {
        this.findAndFocusPod(pod)
      }
    },
    async privateRemoveStreamPod(stream) {
      if (stream.pod) {
        if (!stream.pod.attendee) {
          this.removePod(stream.pod)
        } else if (stream.pod.video && stream.pod.video.id === stream.id) {
          this.syncPodVideoStream(stream.pod)
          stream.pod.video = null
        }
      }
    },
    async privateRemovePod(data) {
      if (!data.pod) return
      if (!this.podsById[data.pod.id]) return
      this.removingPod.add(data.pod.id)

      console.log('privateRemovePod', this.activeFullscreenPod?.id === data.pod.id)
      if (this.activeFullscreenPod && this.activeFullscreenPod.id === data.pod.id) {
        if (!this.privateToggleFullscreenPod) {
          this.removePodFromFullscreen(data.pod, true)
        }
        this.removePod(data.pod, data.sendMessage)
        return
      }

      if (data.pod.video) {
        this.removeStreamSubscriber('camera', data.pod.video.id)
        data.pod.video.pod = null
        data.pod.video = null
      }

      let idx
      if (data.pod.nextToPod) {
        idx = data.pod.nextToPod.children.indexOf(data.pod)
        if (idx !== -1) data.pod.nextToPod.children.splice(idx, 1)
      }
      if (data.pod.children.length) {
        for (let childPod of data.pod.children) childPod.nextToPod = null
        data.pod.children = []
      }

      idx = this.pods.indexOf(data.pod)
      if (idx !== -1) this.pods.splice(idx, 1)
      delete this.podsById[data.pod.id]
      this.removingPod.delete(data.pod.id)
      this.podsLength -= 1
      if (this.podsLength < 0) this.podsLength = 0

      console.debug(`Pod ${data.pod.id} "${data.pod.name}" removed`)

      if (data.pod.nextToPod) data.pod.nextToPod.checkVisibility()

      if (this.isRunning && data.sendMessage) {
        if (!this.podsLeftQueue.names.length) {
          this.podsLeftQueue.nextRunMs = new Date().getTime() + 1000
        }
        this.podsLeftQueue.names.push(data.pod.name)
      }
    },
    async privatePodsleftMessage(names) {
      let message = ''
      if (names.length === 1) {
        message = names[0] + ' saiu da sessão.'
      } else {
        message = names.join(', ') + ' saíram da sessão.'
      }

      this.$buefy.snackbar.open({
        message: message,
        type: 'is-light',
        position: 'is-top-right',
        duration: 5000,
        queue: false
      })
    },
    async privateSetPodVideoStream(podId) {
      let pod = this.podsById[podId]
      if (!pod) return
      else if (!pod.elementCreated) return this.syncPodVideoStream(pod)

      let element = document.getElementById('video-' + pod.id)
      if (!element) {
        pod.elementCreated = false
        return this.syncPodVideoStream(pod)
      }

      let removeElements = []
      let addVideo = Boolean(pod.video)
      for (let streamElement of element.children) {
        if (addVideo && pod.video.htmlElement === streamElement) addVideo = false
        else removeElements.push(streamElement)
      }

      if (removeElements.length) {
        for (let i of removeElements) element.removeChild(i)
      }

      if (addVideo) element.appendChild(pod.video.htmlElement)
      if (pod.video) this.attachMediaToStream(pod.video)
      pod.checkVisibility()
    },
    async privateSetPageData(newPage = null) {
      if (process.env.VUE_APP_KEY === 'ucmeetingscreen') return

      const container = document.getElementById('meeting-pods') || document.body
      if (!container || !this.podsLength) return

      // Find max number of pods we can set in a page
      const containerWidth = container.offsetWidth - 60
      const containerHeight = container.offsetHeight - 60
      const maxRows = Math.floor(containerWidth / this.pageSettings.minPodWidth) || 1
      const maxColumns = Math.floor(containerHeight / this.pageSettings.minPodHeight) || 1
      const maxPodsPerPage = maxRows * maxColumns
      const newPodsPerPage = Math.min(this.podsLength, this.pageSettings.current, maxPodsPerPage)
      if (newPodsPerPage !== this.podsPerPage) this.podsPerPage = newPodsPerPage

      // Build page data
      const newNumberOfPages = Math.ceil(this.podsLength / this.podsPerPage)
      if (newNumberOfPages !== this.numberOfPages) this.numberOfPages = newNumberOfPages

      if (newPage === null) newPage = Math.min(this.activePage, this.numberOfPages || 1)
      else if (newPage > this.numberOfPages + 1) newPage = this.numberOfPages
      else if (newPage < 0) newPage = 0
      if (newPage !== this.activePage) this.activePage = newPage

      let newCurrentPagePods = Math.min(this.podsLength, this.podsPerPage)
      if (this.numberOfPages > 1 && this.activePage === this.numberOfPages) {
        newCurrentPagePods = this.podsLength % this.podsPerPage || this.podsPerPage
      }
      if (newCurrentPagePods !== this.currentPagePods) this.currentPagePods = newCurrentPagePods

      // Find best order
      let rows = maxRows
      let columns = maxColumns
      let newPodWidth = this.pageSettings.minPodWidth
      let newPodHeight = this.pageSettings.minPodHeight
      if (this.currentPagePods < maxPodsPerPage) {
        if (this.currentPagePods === 1) {
          newPodWidth = containerWidth
          newPodHeight = containerHeight
          rows = columns = 1
        } else {
          const iMaxRows = Math.min(this.currentPagePods, maxRows)
          const podRatio = this.pageSettings.minPodWidth / this.pageSettings.minPodHeight
          for (let rowCount = 1; rowCount <= iMaxRows; rowCount++) {
            const colCount = Math.ceil(this.currentPagePods / rowCount)
            if (colCount > maxColumns) continue

            // Check by width
            const maxWidth = Math.floor(containerWidth / colCount)
            if (maxWidth > newPodWidth) {
              let iHeight = Math.floor(maxWidth / podRatio)
              if (iHeight >= this.pageSettings.minPodHeight && iHeight * rowCount <= containerHeight) {
                newPodWidth = maxWidth
                newPodHeight = iHeight
                rows = rowCount
                columns = colCount
              }
            }

            // Check by height
            let maxHeight = Math.floor(containerHeight / rowCount)
            if (maxHeight > newPodHeight) {
              let iWidth = Math.floor(maxHeight * podRatio)
              if (iWidth >= this.pageSettings.minPodWidth && iWidth * colCount <= containerWidth) {
                newPodWidth = iWidth
                newPodHeight = maxHeight
                rows = rowCount
                columns = colCount
              }
            }
          }
        }
      }

      // Change the pod size via CSS property
      newPodWidth = Math.max(Math.floor(newPodWidth), Math.floor(this.pageSettings.minPodWidth))
      if (newPodWidth !== this.podWidth || !container.style.getPropertyValue('--item-width')) {
        this.podWidth = newPodWidth
        container.style.setProperty('--item-width', `${this.podWidth}px`)
      }
      newPodHeight = Math.max(Math.floor(newPodHeight), Math.floor(this.pageSettings.minPodHeight))
      if (newPodHeight !== this.podHeight || !container.style.getPropertyValue('--item-height')) {
        this.podHeight = newPodHeight
        container.style.setProperty('--item-height', `${this.podHeight}px`)
      }

      console.debug(
        `Pods page ${newPage} pods:${this.podsLength} page:${this.activePage}/${this.numberOfPages} ` +
          `pagePods:${this.currentPagePods}(${this.podsPerPage}) rows:${rows} columns:${columns} ` +
          `pod:${this.podWidth}x${this.podHeight} container:${containerWidth}x${containerHeight}`
      )
    },
    async privateBuildSetFullscreenPod() {
      if (this.privateToggleFullscreenPod.pod === this.activeFullscreenPod) {
        // Already in fullscreen
        this.privateToggleFullscreenPod = null
        return
      }
      if (!this.pods.includes(this.privateToggleFullscreenPod.pod)) {
        // Pod removed
        this.privateToggleFullscreenPod = null
        return
      }

      if (this.activeFullscreenPod) {
        console.debug(`Pod ${this.activeFullscreenPod.id} fullscreen removed`)
        this.activeFullscreenPod.fullscreen = false
      }

      this.activeFullscreenPod = this.privateToggleFullscreenPod.pod
      this.activeFullscreenPod.fullscreen = true
      this.privateToggleFullscreenPod = null
      this.withFullscreen = true
      console.debug(`Pod ${this.activeFullscreenPod.id} fullscreen added`)

      // Maybe the pod it's in another page
      const podPage = this.findPodPage(this.activeFullscreenPod)
      if (podPage && podPage !== this.activePage) {
        console.debug(`Pod ${this.activeFullscreenPod.id} is in page ${podPage} we are in ${this.activePage}, moving`)
        this.changePage(podPage)
      }

      this.reloadPage()
    },
    async privateBuildRemoveFullscreenPod() {
      if (this.activeFullscreenPod && this.activeFullscreenPod !== this.privateToggleFullscreenPod.pod) {
        // Another pod already in fullscreen
        this.privateToggleFullscreenPod = null
        return
      }

      let nextPod = null
      if (this.privateToggleFullscreenPod.activateNextScreenShare) {
        for (let i = 0; i < this.pods.length; i++) {
          let pod = this.pods[i]
          if (pod.type === 'screen_share' && pod.id !== this.privateToggleFullscreenPod.pod.id) {
            nextPod = pod
            break
          }
        }
      }

      if (this.activeFullscreenPod) {
        this.activeFullscreenPod.fullscreen = false
        this.withFullscreen = false
        console.debug(`Pod ${this.activeFullscreenPod.id} fullscreen removed`)
        this.activeFullscreenPod = null
      }

      if (!nextPod) {
        // No more pods in this page? Go to page 1
        if (!this.activePods.find(pod => pod.id !== this.privateToggleFullscreenPod.id)) {
          console.debug(`No more pods in current page ${this.activePage}, go to page 1`)
          this.changePage(1)
        }

        this.privateToggleFullscreenPod = null
        this.reloadPage()
      } else {
        this.privateToggleFullscreenPod = { pod: nextPod }
        this.privateBuildSetFullscreenPod()
      }
    },
    async privateBuildToggleFullscreenPod() {
      if (this.privateToggleFullscreenPod.remove) {
        this.privateBuildRemoveFullscreenPod()
      } else {
        this.privateBuildSetFullscreenPod()
      }
    },
    async privateBuildActivePagePods() {
      if (this.podsLength === 1 && this.activePage > 0) {
        if (!this.withFullscreen) {
          this.pods[0].fullscreen = true
          this.withFullscreen = true
        }
      } else if (this.withFullscreen && !this.activeFullscreenPod) {
        this.withFullscreen = false
        for (let i = 0; i < this.pods.length; i++) {
          let pod = this.pods[i]
          if (pod.fullscreen) pod.fullscreen = false
        }
      }

      let newActivePods = []
      if (process.env.VUE_APP_KEY === 'ucmeetingscreen') {
        if (this.showUcMeetingScreenInfo()) {
          for (let i = 0; i < this.pods.length; i++) {
            let pod = this.pods[i]
            if (
              !newActivePods.length &&
              pod.type !== 'screen_share' &&
              pod.attendee &&
              pod.attendee.withRole('can_start_room')
            ) {
              newActivePods.push(pod)
              if (pod.video) {
                this.updateStream(pod.video, true, 'high')
              }
            } else if (pod.video) {
              this.updateStream(pod.video, false, 'low')
            }
          }
        }

        // Rules for the screen used on Framework's Control Room
        else if (this.showUcMeetingScreenInfoFull()) {
          let myPod = null
          for (let i = 0; i < this.pods.length; i++) {
            let pod = this.pods[i]
            if (
              !newActivePods.length &&
              pod.type !== 'screen_share' &&
              pod.attendee &&
              pod.attendee.withRole('can_start_room')
            ) {
              myPod = pod
              if (pod.video) {
                this.updateStream(pod.video, true, 'high')
              }
            } else if (pod.video) {
              this.updateStream(pod.video, true, 'low')
              newActivePods.push(pod)
            }
          }

          // Order the pods prioritizing video
          newActivePods.sort((a, b) => {
            if (a.video != null && b.video == null) {
              return -1
            } else if (a.video == null && b.video != null) {
              return 1
            } else {
              return a.name.localeCompare(b.name)
            }
          })
          // Push mypod to the start
          if (myPod) {
            newActivePods.unshift(myPod)
          }

          // Limit the number of pods
          const podsMax = this.ucMeetingScreenPodsMax()
          newActivePods = newActivePods.slice(0, podsMax + 1)
        } else if (this.showUcMeetingScreenShare()) {
          const screenPods = []
          const otherPods = []
          const activeScreenPod =
            this.activeFullscreenPod && this.activeFullscreenPod.video ? this.activeFullscreenPod : null
          for (let i = 0; i < this.pods.length; i++) {
            let pod = this.pods[i]
            if (pod.isUcMiddleware) {
              if (pod.video) {
                this.updateStream(pod.video, false, 'low')
              }
            } else if (!activeScreenPod || pod.id !== activeScreenPod.id) {
              if (pod.type === 'screen_share') {
                screenPods.push(pod)
              } else if (pod.attendee && !pod.attendee.withRole('can_start_room')) {
                otherPods.push(pod)
              } else if (pod.video) {
                this.updateStream(pod.video, false, 'low')
              }
            }
          }

          screenPods.push(
            ...otherPods.sort((a, b) => {
              if (a.video != null && b.video == null) {
                return -1
              } else if (a.video == null && b.video != null) {
                return 1
              } else {
                return a.name.localeCompare(b.name)
              }
            })
          )

          let setActivePods = activeScreenPod ? 11 : 24
          if (activeScreenPod) setActivePods--

          newActivePods = screenPods.slice(0, setActivePods)
          newActivePods.forEach(pod => {
            if (pod.video) {
              this.updateStream(pod.video, true, activeScreenPod ? 'normal' : 'high')
            }
          })
          if (activeScreenPod) {
            newActivePods.unshift(activeScreenPod)
            this.updateStream(activeScreenPod.video, true, 'high')
          }
          screenPods.slice(setActivePods).forEach(pod => {
            if (pod.video) {
              this.updateStream(pod.video, false, 'low')
            }
          })
        } else if (this.showUcMeetingWideScreen()) {
          let myPod = null
          for (let i = 0; i < this.pods.length; i++) {
            let pod = this.pods[i]
            if (
              !newActivePods.length &&
              pod.type !== 'screen_share' &&
              pod.attendee &&
              pod.attendee.withRole('can_start_room')
            ) {
              //this is my pod
              //newActivePods.push(pod)
              myPod = pod
              if (pod.video) {
                this.updateStream(pod.video, true, 'high')
              }
            } else if (pod.video) {
              this.updateStream(pod.video, true, 'low')
              newActivePods.push(pod)
            }
          }
          //order the pods prioritizing video
          newActivePods.sort((a, b) => {
            if (a.video != null && b.video == null) {
              return -1
            } else if (a.video == null && b.video != null) {
              return 1
            } else {
              return a.name.localeCompare(b.name)
            }
          })
          //push mypod to the start
          if (myPod) {
            newActivePods.unshift(myPod)
          }
          //limit the number of pods
          const podsMax = this.ucMeetingScreenPodsMax()
          newActivePods = newActivePods.slice(0, podsMax + 1)
          //make them visible
          /*newActivePods.forEach(pod => {
            if (pod.video) {
              this.updateStream(
                pod.video,
                true,
                pod.attendee && pod.attendee.withRole('can_start_room') ? 'high' : 'low'
              )
            }
          })*/
        } else {
          let screenPods = []
          for (let i = 0; i < this.pods.length; i++) {
            let pod = this.pods[i]
            if (
              pod.attendee &&
              pod.type !== 'screen_share' &&
              !pod.attendee.withRole('can_start_room') &&
              !pod.attendee.withRole('is_room_plugin')
            ) {
              screenPods.push(pod)
            } else if (pod.video) {
              this.updateStream(pod.video, false, 'low')
            }
          }
          screenPods.sort((a, b) => {
            if (a.attendee.created_date_date > b.attendee.created_date_date) {
              return -1
            } else if (a.attendee.created_date_date < b.attendee.created_date_date) {
              return 1
            } else {
              return a.name.localeCompare(b.name)
            }
          })

          const screenNumber = this.ucMeetingScreenPosition() - 1
          const totalScreens = this.ucMeetingScreenTotal()
          const podsMax = this.ucMeetingScreenPodsMax()
          const totalPodsMax = totalScreens * podsMax

          if (screenPods.length > totalPodsMax) {
            // We need to add video pods to visible
            const hiddenPods = screenPods.slice(totalPodsMax)
            screenPods = screenPods.slice(0, totalPodsMax)
            let checkAudio = false
            for (const hiddenPod of hiddenPods) {
              if (hiddenPod.video != null) {
                let updated = false
                if (!checkAudio) {
                  // Add it to visible pods, if we have slots
                  for (const index in screenPods) {
                    const screenPod = screenPods[index]
                    if (screenPod.video == null) {
                      screenPods[index] = hiddenPod
                      updated = true
                      break
                    }
                  }
                  if (!updated) {
                    checkAudio = true
                  }
                }

                if (!updated && hiddenPod.withAudio) {
                  // No more slots available, let's try to replace with pods without audio
                  for (const index in screenPods) {
                    const screenPod = screenPods[index]
                    if (!screenPod.withAudio) {
                      screenPods[index] = hiddenPod
                      break
                    }
                  }
                }
              }
            }
          }

          newActivePods = screenPods.filter((e, i) => i % totalScreens == screenNumber).slice(0, podsMax)
          newActivePods.forEach(pod => {
            if (pod.video) {
              this.updateStream(pod.video, true, 'high')
            }
          })
        }
      } else {
        let simulcast = 'low'
        if (this.podsLength && this.view === 'participants' && !this.activeFullscreenPod) {
          if (this.podWidth >= 600) simulcast = 'high'
          else if (this.podWidth > 300) simulcast = 'normal'
        }

        for (let i = 0; i < this.pods.length; i++) {
          let pod = this.pods[i]
          let isVisible = Math.trunc(i / this.podsPerPage) + 1 === this.activePage
          if (isVisible) newActivePods.push(pod)

          if (pod.video) {
            if (pod.fullscreen) {
              this.updateStream(pod.video, true, 'high')
              if (!isVisible) newActivePods.push(pod)
            } else {
              this.updateStream(pod.video, isVisible, simulcast)
            }
          }
        }
      }

      // Check if other users audio is OK
      this.checkAllAudio()

      if (this.camera.active) this.camera.checkBitrate()
      if (this.screenShare.active) this.screenShare.checkBitrate()

      // Check if we have different pods
      if (this.activePods.length === newActivePods.length) {
        let different = false
        for (let i = 0; i < this.activePods.length; i++) {
          if (this.activePods[i] !== newActivePods[i]) {
            different = true
            break
          }
        }
        if (!different) return
      }
      this.activePods = newActivePods
    },

    // Queued methods
    addAttendeePod(attendee) {
      this.podsQueue.addAttendees.push(attendee.key)
      this.runPodsQueue()
    },
    refreshAttendeeVideo(attendee) {
      this.podsQueue.refreshAttendeeVideos.push(attendee.key)
      this.runPodsQueue()
    },
    addStreamPod(stream) {
      this.podsQueue.addStreams.push(stream.id)
      this.runPodsQueue()
    },
    removeStreamPod(stream) {
      this.podsQueue.removeStreams.push(stream)
      this.runPodsQueue()
    },
    removePod(pod, sendMessage = true) {
      this.podsQueue.remove.push({ pod: pod, sendMessage: sendMessage })
      this.runPodsQueue()
    },
    syncPodVideoStream(pod) {
      if (pod.elementCreated) {
        this.podsQueueVideoStream.add(pod.id)
        this.runPodsQueue()
      }
    },
    findAndFocusPod(pod) {
      this.podsFindAndFocusId = pod.id
      this.runPodsQueue()
    },
    reloadPage() {
      this.$nextTick(this.runPodsQueue)
    },
    findPodPage(pod) {
      const idx = this.pods.indexOf(pod)
      return idx !== -1 ? Math.ceil((idx + 1) / this.podsPerPage) : null
    },
    changePage(newPage = null) {
      if (newPage !== null) this.podsQueueChangeToPage = newPage
      else if (this.podsQueueChangeToPage !== undefined) this.podsQueueChangeToPage = null
      this.runPodsQueue()
    },
    toggleFullscreen(pod) {
      if (this.activeFullscreenPod === pod) {
        this.removePodFromFullscreen(pod)
      } else {
        this.setPodToFullscreen(pod)
      }
    },
    setPodToFullscreen(pod) {
      //avoid fullscreen in ucmeeting screen if its a room plugin
      if (process.env.VUE_APP_KEY === 'ucmeetingscreen' && pod.isUcMiddleware) return
      this.privateToggleFullscreenPod = { pod }
      this.runPodsQueue()
      this.$emit('set-view', 'participants')
    },
    removePodFromFullscreen(pod, activateNextScreenShare = false) {
      this.privateToggleFullscreenPod = { remove: true, pod, activateNextScreenShare }
      this.runPodsQueue()
      this.$emit('set-view', 'participants')
    },
    toggleFloatingStreamVisibility() {
      this.floatingStreamsVisible = !this.floatingStreamsVisible
      localStorage.setItem('pods.visible', this.floatingStreamsVisible ? 'true' : 'false')
    },
    toggleToolboxCall(value) {
      this.isToolboxCallExpanded = value || !this.isToolboxCallExpanded
    },
    togglePodsSubmenu() {
      this.podsSubmenu = !this.podsSubmenu
      if (this.podsSubmenu) this.chatSubmenu = false
      this.reloadPage()
    },
    toggleChatSubmenu() {
      this.chatSubmenu = !this.chatSubmenu
      if (this.chatSubmenu) this.podsSubmenu = false
      this.reloadPage()
    },
    async blockAttendeeAudio(attendee) {
      try {
        const roles = {}
        roles[ServiceMeetings.getRole('audio_allowed')] = false
        attendee.roles = await ServiceMeetings.changeAttendeeRoles(this.meeting.key, attendee.key, roles)
        this.reloadPage()
      } catch (error) {
        // Ignored, could be a meeting host and we can't lower the hand
      }
    },
    async blockAttendeeScreenShare(attendee) {
      try {
        const roles = {}
        roles[ServiceMeetings.getRole('sharescreen_allowed')] = false
        attendee.roles = await ServiceMeetings.changeAttendeeRoles(this.meeting.key, attendee.key, roles)
        this.reloadPage()
      } catch (error) {
        // Ignored, could be a meeting host and we can't lower the hand
      }
    },
    setHandsUpCore(attendee) {
      if (!attendee || this.attendee.key === attendee.key) return
      else if (this.attendeesHandsUp.includes(attendee.key)) return

      this.$notification.show(
        `${attendee.name} pediu a palavra`,
        {
          icon: ServiceStorage.getUserImageViewUrl(attendee.user)
        },
        {}
      )
      this.attendeesHandsUp.push(attendee.key)
      console.debug(`Hand up ${attendee.key}`)

      if (this.attendeesHandsUp.length) {
        if (!this.withHandsUp) this.withHandsUp = true
      } else if (this.withHandsUp) {
        this.withHandsUp = false
      }
    },
    removeHandsUpCore(attendee, leaving = false) {
      console.log('removeHandsUpCore', attendee, leaving)
      for (let i = this.attendeesHandsUp.length - 1; i >= 0; i--) {
        let key = this.attendeesHandsUp[i]
        if (attendee.key == key) {
          this.attendeesHandsUp.splice(i, 1)
          console.debug(`Hand down ${key}`)

          /*
          Do not block audio in ucdigitaldesk after removing hands up
          if (!leaving && process.env.VUE_APP_KEY == 'ucdigitaldesk' && attendee.withRole('audio_allowed')) {
            this.blockAttendeeAudio(attendee)
          }*/
          break
        }
      }

      if (this.attendeesHandsUp.length) {
        if (!this.withHandsUp) this.withHandsUp = true
      } else if (this.withHandsUp) {
        this.withHandsUp = false
      }
    },
    addAttendeeCore(attendee) {
      if (this.attendee.key === attendee.key) return
      else if (this.attendees[attendee.key]) return

      // Add some utilities to attendee
      attendee.created_date_date = new Date(attendee.created_date)
      attendee.withRole = function(key) {
        return ServiceMeetings.withRole(this.roles, key) // TODO improve, use "Set"
      }
      attendee.canBePromoted = function() {
        return !this.plugin_for_attendee_key && !this.withRole('is_host')
      }

      this.attendees[attendee.key] = attendee

      if (attendee.withRole('is_room_plugin')) {
        this.roomAttendees.push(attendee)
        if (attendee.withRole('show_to_everyone')) {
          this.addAttendeePod(attendee)
        }
      } else {
        this.addAttendeePod(attendee)

        if (this.addChatUser) {
          this.addChatUser(attendee.user)
        }
        if (this.allowClassSessionView && this.activeClassSession) {
          let found = false
          for (let presence of this.activeClassSession.presences) {
            if (presence.user.key === attendee.user.key) {
              found = true
              if (presence.student && !presence.locked && (!presence.type || presence.type === 'nonio')) {
                presence.type = 'online'
              }
            }
          }
          if (!found) {
            this.activeClassSession.presences.push({
              student: null,
              user: attendee.user,
              type: 'online',
              locked: false
            })
          }
        }
      }
      if (attendee.hand_up) {
        this.setHandsUpCore(attendee)
      }
    },
    updateAttendees(items, updateType, withLog = true, withMessage = true) {
      if (!this.instance) return

      const instanceKey = this.instance.key
      const itSelfAttendee = this.attendee
      const itSelfAttendeeKey = itSelfAttendee.key
      const selfIsRoomPlugin = itSelfAttendee.withRole('is_room_plugin')

      for (const message of items) {
        if (instanceKey !== message.instance_key) continue

        if (updateType === 'delta') {
          for (let key of message.keys) {
            const itSelf = itSelfAttendeeKey === key
            const attendee = itSelf ? itSelfAttendee : this.attendees[key]
            if (!attendee) continue

            const previous_audio_allowed = attendee.withRole('audio_allowed')
            const previousCameraId = attendee.camera_id
            const previousScreenId = attendee.screen_share_id
            Object.assign(attendee, message.delta)

            if (itSelf) {
              if (!previous_audio_allowed && attendee.withRole('audio_allowed')) {
                this.showAudioUnlockedModal = true
              }
              if (this.audio.active && !attendee.withRole('audio_allowed')) {
                this.stopAudio()
              }
              if (this.camera.active && !attendee.withRole('camera_allowed')) {
                this.stopCamera()
              }
              if (this.screenShare.active && !attendee.withRole('sharescreen_allowed')) {
                this.stopShareScreen()
              }
            } else {
              if (attendee.hand_up) this.setHandsUpCore(attendee)
              else this.removeHandsUpCore(attendee)

              if (attendee.camera_id !== previousCameraId) {
                this.refreshAttendeeVideo(attendee)
              }
              if (attendee.screen_share_id !== previousScreenId) {
                this.addStreamSubscriber('screen_share', attendee.screen_share_id, attendee)
              }

              if (!selfIsRoomPlugin && attendee.withRole('is_room_plugin')) {
                if (attendee.withRole('show_to_everyone')) {
                  if (!attendee.pod) {
                    this.addAttendeePod(attendee)
                  }
                } else if (attendee.pod) {
                  this.removePod(attendee.pod, false)
                  attendee.pod = null
                }
              }
            }
          }

          if (withLog) {
            console.debug(`Attendees updated ${JSON.stringify(message.keys)} ${JSON.stringify(message.delta)}`)
          }
        } else if (updateType === 'delete') {
          for (let key of message.keys) {
            const attendee = this.attendees[key]
            if (!attendee) continue

            if (this.updateAudioTalkingCore) this.updateAudioTalkingCore(attendee.key, false)
            if (attendee.hand_up) this.removeHandsUpCore(attendee, true)

            if (this.removeAttendeeChat) this.removeAttendeeChat(attendee)
            delete this.attendees[key]
            const roomIndex = this.roomAttendees.indexOf(attendee)
            if (roomIndex !== -1) this.roomAttendees.splice(roomIndex, 1)
            this.removePod(attendee.pod, withMessage)

            // Remove attendee screen share pods
            if (attendee.screen_share_id) {
              const stream = this.streamsById[attendee.screen_share_id]
              if (stream) this.removeStreamPod(stream)
            }
          }

          if (withLog) {
            console.debug(`Attendees left ${JSON.stringify(message.keys)}`)
          }
        } else {
          for (let attendee of message.attendees) {
            this.addAttendeeCore(attendee)
          }
          if (this.sortChatUsers) {
            this.sortChatUsers()
          }
          if (withLog) {
            console.debug('New attendees', message.attendees)
          }
        }
      }

      this.reloadPage()
      if (this.setAllChatUnread) this.setAllChatUnread()
    },
    lowerAttendeeHand(attendee) {
      const idx = this.attendeesHandsUp.indexOf(attendee.key)
      if (idx !== -1) {
        this.attendeesHandsUp.splice(idx, 1)
        attendee.hand_up = false
        ServiceMeetings.lowerAttendeeHand(this.meeting.key, attendee.key)
      }
    },
    setAttendees(items, with_log = true, withMessage = true) {
      this.updateAttendees(items, 'add', with_log, withMessage)
    },
    setAttendeesDelta(items, with_log = true) {
      this.updateAttendees(items, 'delta', with_log)
    },
    removeAttendees(items, with_log = true, withMessage = true) {
      this.updateAttendees(items, 'delete', with_log, withMessage)
    },
    countUserAttendees(userKey) {
      let length = 0
      for (const attendee of Object.values(this.attendees)) {
        if (attendee.user && attendee.user.key === userKey) length += 1
      }
      return length
    },

    setWaitingAttendees(messages) {
      if (!this.instance) return

      for (let message of messages) {
        if (message.instance_key == this.instance.key) {
          for (let attendee of message.attendees) {
            let waitingAttendeeExists = false
            for (let waitingAttendee of this.waitingAttendees) {
              if (waitingAttendee.key === attendee.key) {
                waitingAttendeeExists = true
                break
              }
            }
            if (!waitingAttendeeExists) {
              this.waitingAttendees.push(attendee)
              console.debug('Got new waiting attendee', attendee)
            }
          }
        }
      }
    },
    removeWaitingAttendees(messages) {
      let removeKeys = new Set()
      messages.forEach(function(message) {
        message.keys.forEach(function(key) {
          removeKeys.add(key)
        })
      })
      if (removeKeys.size) {
        for (let i = this.waitingAttendees.length - 1; i >= 0; i--) {
          if (removeKeys.has(this.waitingAttendees[i].key)) {
            this.waitingAttendees.splice(i, 1)
          }
        }
        console.debug('Waiting attendees left', removeKeys)
      }
    },

    buildAttendeeName(attendee) {
      return attendee.name ? attendee.name : attendee.user ? attendee.user.name : attendee.key
    },
    async toggleHand() {
      if (this.attendee.hand_up) {
        await ServiceMeetings.removeAttendeeFlag(this.meeting.key, 'hand')
        this.attendee.hand_up = false
      } else {
        await ServiceMeetings.setAttendeeFlag(this.meeting.key, 'hand')
        this.attendee.hand_up = true
      }
    },
    async muteAll() {
      const mutedAttendeeKeys = await ServiceMeetings.muteAll(this.meeting.key)
      this.removeAudios(mutedAttendeeKeys)
    },
    async muteAttendee(attendee) {
      await ServiceMeetings.muteAttendee(this.meeting.key, attendee.key)
      this.removeAudios([attendee.key])
    },

    showUcMeetingScreenInfo() {
      return localStorage.getItem('show-info') == 'true'
    },
    showUcMeetingScreenInfoFull() {
      return localStorage.getItem('show-info-full') == 'true'
    },
    showUcMeetingScreenShare() {
      return localStorage.getItem('show-screenshare') == 'true'
    },
    showUcMeetingWideScreen() {
      return localStorage.getItem('show-widescreen') == 'true'
    },
    ucMeetingScreenPosition() {
      return parseInt(localStorage.getItem('screenNumber')) || 1
    },
    ucMeetingScreenTotal() {
      return parseInt(localStorage.getItem('totalScreens')) || 2
    },
    ucMeetingScreenPodsMax() {
      return localStorage.getItem('is-vertical') == 'true' ? 8 : 24 //12
    },
    isHybridRoomShare(pod) {
      return (
        pod.name.toLowerCase().includes('primário') ||
        pod.name.toLowerCase().includes('primary') ||
        pod.name.toLowerCase().includes('principal') ||
        pod.name.toLowerCase().includes('secundário') ||
        pod.name.toLowerCase().includes('secondary')
      )
    }
  }
}
