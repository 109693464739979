export default [
  {
    path: '/manage/docs',
    name: 'manage-docs',
    component: () => import('@/views/manage/ManageDocs'),
    meta: { requiresAuth: true, requiresPermissions: true }
  },

  {
    path: '/docs/edit/:key/:version',
    name: 'manage-docs-editor-with-version',
    component: () => import('@/views/manage/ManageDocs'),
    meta: { requiresAuth: true, requiresPermissions: true, edit: true }
  },
  {
    path: '/docs/translate/:key/:version',
    name: 'manage-docs-translator',
    component: () => import('@/views/manage/ManageDocs'),
    meta: { requiresAuth: true, requiresPermissions: true, edit: true, translator: true }
  },
  {
    path: '/docs/edit/:key',
    name: 'manage-docs-editor',
    component: () => import('@/views/manage/ManageDocs'),
    meta: { requiresAuth: true, requiresPermissions: true, edit: true }
  },
  {
    path: '/docs/:key/:version',
    name: 'manage-docs-viewer-with-version',
    component: () => import('@/views/manage/ManageDocs'),
    meta: { requiresAuth: true, requiresPermissions: true, edit: false }
  },
  {
    path: '/docs/:key',
    name: 'manage-docs-viewer',
    component: () => import('@/views/manage/ManageDocs'),
    meta: { requiresAuth: true, requiresPermissions: true, edit: false }
  }
]
