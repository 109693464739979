import CoreActions from '@/fw-modules/fw-core-vue/store/actions'

export default Object.assign(CoreActions, {
  setPermissionsLoaded(context, loaded) {
    context.commit('setPermissionsLoaded', loaded)
  },
  setLastReceipt(context, receiptype, receiptDate) {
    if (receiptype == 'irs') {
      context.commit('setLastIrs', receiptDate)
    } else {
      context.commit('setLastPaySlip', receiptDate)
    }
  }
})
